<template>
  <div class="columns profile--settings">
    <div
      v-if="subscription && subscription.status"
      class="billing-page-content"
    >
      <section>
        <div class="fs-medium">
          {{ $t('BILLING_SETTINGS.CURRENT_PLAN.PLAN_NAME') }}
          <span v-if="isTrial">
            <span class="color-primary">
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.FREE_TRIAL') }}
            </span>
            of the
          </span>
          <span class="color-primary">
            {{ planName }} {{ $t('BILLING_SETTINGS.CURRENT_PLAN.PLAN') }}
          </span>
        </div>
        <div class="fs-normal">
          {{ $t('BILLING_SETTINGS.CURRENT_PLAN.NEXT_CHARGE_TEXT') }}
          <span class="fw-medium">{{ nextPaymentDate }}</span> .
        </div>
      </section>
      <section v-if="isChildSubscription" class="billing-page-alert">
        <div class="billing-page-alert-icon">
          {{ '💡' }}
        </div>
        <div>
          <div class="fw-medium fs-default">
            {{ $t('BILLING_SETTINGS.CURRENT_PLAN.CHILD_SUBSCRIPTION_TITLE') }}
          </div>
          <div class="fs-normal">
            {{
              $t('BILLING_SETTINGS.CURRENT_PLAN.CHILD_SUBSCRIPTION_DESCRIPTION')
            }}
          </div>
        </div>
      </section>
      <template v-if="!isChildSubscription">
        <section v-if="isTrial" class="billing-page-alert">
          <div class="billing-page-alert-icon">
            {{ hasDefaultPaymentMethod ? '👋' : '🚨' }}
          </div>
          <div>
            <div class="fw-medium fs-default">
              {{ alertTitle }}
            </div>
            <div class="fs-normal">
              {{ alertDescription }}
            </div>
            <woot-button
              class="activate-account-btn"
              type="button"
              variant="smooth"
              color-scheme="secondary"
              @click="
                onClickBillingPortal(
                  hasDefaultPaymentMethod
                    ? 'portal-session'
                    : 'card-checkout-session'
                )
              "
            >
              {{ alertBtn }}
            </woot-button>
          </div>
        </section>
        <section v-if="hasPaymentMethod" class="fs-normal color-primary">
          {{
            $t('BILLING_SETTINGS.CURRENT_PLAN.PAYMENT_METHOD', {
              card: getCardLastNumbers,
              brand: getCardBrand,
              expire: getCardExpireDate,
            })
          }}
        </section>
        <section class="subscription-details-content">
          <div class="subscription-details-header">
            <div class="fs-medium fw-medium">
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.SUBSCRIPTION_DETAILS') }}
            </div>
            <woot-button
              v-if="hasDefaultPaymentMethod"
              type="button"
              variant="smooth"
              color-scheme="primary"
              @click="openUpdateBillingPopup()"
            >
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.UPDATE_PLAN') }}
            </woot-button>
          </div>
          <div class="subscription-details-item">
            <div class="subscription-details-item-header">
              {{ planName }} {{ $t('BILLING_SETTINGS.CURRENT_PLAN.PLAN') }}
            </div>
            <div v-if="isTrial" class="subscription-details-item-info">
              <div class="fs-default">
                {{ subscription.quantity.toString() }} x {{ planName }} (at ${{
                  subscriptionItemPrice(subscription.items.mainSubscriptionItem)
                }}
                / {{ paymentPeriod }})
              </div>
              <div class="fw-medium fs-normal">
                $
                {{
                  subscriptionItemPrice(
                    subscription.items.mainSubscriptionItem,
                    subscription.items.mainSubscriptionItem.quantity
                  )
                }}
              </div>
            </div>
            <template v-for="item in mainItems" v-else>
              <div
                v-if="item.quantity"
                :key="item.id"
                class="subscription-details-item-info"
              >
                <div class="fs-default">
                  {{ item.description }}
                </div>
                <div class="fw-medium fs-normal">
                  $
                  {{ subscriptionItemPrice(item, item.quantity) }}
                </div>
              </div>
            </template>
          </div>

          <div
            v-if="!isTrial && webCallItems.length"
            class="subscription-details-item"
          >
            <div class="subscription-details-item-header">
              {{ $t('BILLING_SETTINGS.CURRENT_PLAN.WEB_CALL') }}
            </div>
            <template v-for="item in webCallItems">
              <div
                v-if="item.quantity"
                :key="item.id"
                class="subscription-details-item-info"
              >
                <div class="fs-default">
                  {{ item.description }}
                </div>
                <div class="fw-medium fs-normal">
                  $
                  {{ subscriptionItemPrice(item, item.quantity) }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="!isTrial" class="subscription-details-item">
            <div class="subscription-details-item-info">
              <div class="fs-default fw-medium">
                {{ $t('BILLING_SETTINGS.CURRENT_PLAN.TOTAL') }}
              </div>
              <div class="fw-medium fs-normal color-primary">
                $ {{ paymentDetail.toFixed(2) }}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div v-if="currentUser.role === 'administrator'">
            <billing-item
              :title="$t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.TITLE')"
              :description="
                $t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.DESCRIPTION')
              "
              :button-label="
                $t('BILLING_SETTINGS.MANAGE_SUBSCRIPTION.BUTTON_TXT')
              "
              @click="onClickBillingPortal('portal-session')"
            />
          </div>
          <billing-item
            :title="$t('BILLING_SETTINGS.CHAT_WITH_US.TITLE')"
            :description="$t('BILLING_SETTINGS.CHAT_WITH_US.DESCRIPTION')"
            :button-label="$t('BILLING_SETTINGS.CHAT_WITH_US.BUTTON_TXT')"
            @click="onToggleChatWindow"
          />
        </section>
        <section class="call-based-body">
          <woot-modal
            :show.sync="showUpdateBillingPopup"
            :on-close="hideUpdateBillingPopup"
          >
            <BillingUpdate :on-close="hideUpdateBillingPopup" />
          </woot-modal>
        </section>
      </template>
    </div>
    <woot-loading-state
      v-else
      :message="$t('BILLING_SETTINGS.CURRENT_PLAN.LOADING')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import billingMixin from 'dashboard/mixins/billing';
import accountMixin from '../../../../mixins/account';
import BillingItem from './components/BillingItem.vue';
import BillingUpdate from './components/BillingUpdate.vue';
import wootConstants from 'dashboard/constants/globals.js';
import { format } from 'date-fns';
import axios from 'axios';
import auth from '../../../../api/auth';

const { SERVICES_PATH } = wootConstants;

export default {
  components: { BillingItem, BillingUpdate },
  mixins: [accountMixin, alertMixin, billingMixin],
  data() {
    return {
      SERVICES_PATH,
      newDesign: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    alertTitle() {
      return this.hasDefaultPaymentMethod
        ? this.$t('BILLING_SETTINGS.CURRENT_PLAN.FREE_TRIAL_NOTE', {
            planName: this.planName,
          })
        : this.$t('BILLING_SETTINGS.CURRENT_PLAN.CARD_NOT_ATTACHED');
    },
    alertDescription() {
      return this.hasDefaultPaymentMethod
        ? this.$t('BILLING_SETTINGS.CURRENT_PLAN.TRIAL_END_NOTE', {
            date: this.nextPaymentDate,
            days: this.nextPaymentDays,
          })
        : this.$t('BILLING_SETTINGS.CURRENT_PLAN.ADD_CARD_NOTE');
    },
    alertBtn() {
      return this.hasDefaultPaymentMethod
        ? this.$t('BILLING_SETTINGS.CURRENT_PLAN.ACTIVATE_ACCOUNT')
        : this.$t('BILLING_SETTINGS.CURRENT_PLAN.ATTACH_CARD');
    },
    upcomingInvoiceItems() {
      return this.subscription?.upcomingLines?.data || [];
    },
    webCallItems() {
      return this.upcomingInvoiceItems.filter(item => {
        return this.isCallItem(item);
      });
    },
    mainItems() {
      return this.upcomingInvoiceItems.filter(item => {
        return !this.isCallItem(item);
      });
    },
    paymentPeriod() {
      return `${this.subscription.interval || 'month'}`;
    },
    paymentDetail() {
      return this.subscription.upcomingInvoice?.amount_remaining / 100 || 0;
    },
    nextPaymentDate() {
      return this.isTrial
        ? this.fd(this.subscription.trialEndDate || new Date())
        : this.fd(this.subscription.endDate || new Date());
    },
    nextPaymentDays() {
      if (!this.subscription.trialEndDate) return 0;
      const date = new Date(this.subscription.trialEndDate);
      const diffTime = Math.abs(date - new Date());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    hasPaymentMethod() {
      return this.subscription.paymentMethods?.length > 0;
    },
    getCardLastNumbers() {
      return (
        '****' +
        (this.subscription.paymentMethods.length === 0
          ? ''
          : this.subscription.paymentMethods[0].card.last4)
      );
    },
    getCardBrand() {
      return this.subscription.paymentMethods.length === 0
        ? ''
        : this.subscription.paymentMethods[0].card.brand.toUpperCase();
    },
    getCardExpireDate() {
      const methods = this.subscription.paymentMethods;
      if (methods.length > 0) {
        return `${methods[0].card.exp_year}/${
          methods[0].card.exp_month < 10
            ? '0' + methods[0].card.exp_month
            : methods[0].card.exp_month
        }`;
      }
      return '';
    },
  },
  async mounted() {
    this.$store.dispatch('billing/getBillingInfo', this.accountId);
  },
  methods: {
    isCallItem(item) {
      return item.description.includes('× Call');
    },
    getSubscriptionPeriod() {
      return (
        this.fd(this.subscription.startDate) +
        ' - ' +
        this.fd(this.subscription.endDate)
      );
    },
    getTrialPeriod() {
      return (
        this.fd(this.subscription.trialStartDate) +
        ' - ' +
        this.fd(this.subscription.trialEndDate)
      );
    },

    fd(date) {
      return format(new Date(date), 'MMM d, yyyy');
    },
    onClickBillingPortal(actionUrl) {
      const authToken = auth.getAuthToken();
      axios
        .post(
          `/${SERVICES_PATH.BILLING}/v1/${actionUrl}`,
          {
            accountId: this.accountId,
            returnUrl: `${window.location.href}?redirected=true`,
          },
          { headers: { authorization: authToken } }
        )
        .then(resp => {
          if (resp.data.redirect) {
            window.location = resp.data.url;
          }
        });
    },
    onToggleChatWindow() {
      if (window.$hoory) {
        window.$hoory.toggle();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.billing-page-content {
  width: 75%;
  padding: var(--space-small);
  .billing-page-alert {
    margin: var(--space-medium) 0 var(--space-snug);
    display: flex;
    align-items: start;
    justify-content: start;
    padding: var(--space-two);
    background-color: var(--s-50);
    border-radius: var(--border-radius-normal);
    .billing-page-alert-icon {
      margin-right: var(--space-normal);
      font-size: var(--space-medium);
    }
    .activate-account-btn {
      margin-top: var(--space-normal);
      background-color: var(--white);
      color: var(--s-800);
    }
  }
  .subscription-details-content {
    .subscription-details-header {
      margin-top: var(--space-medium);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .subscription-details-item {
      margin-top: var(--space-two);
      border-bottom: 1px solid var(--s-100);
      padding-bottom: var(--space-one);
      .subscription-details-item-header {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-default);
        color: var(--color-woot);
        margin-bottom: var(--space-normal);
      }
      .subscription-details-item-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--space-small);
      }
    }
  }
}
</style>
